import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './index.scss';

import mast from './mast.jpg';
import symbol from './symbol.svg';

export default class Mast extends Component {
	constructor(props) {
		super(props);

		this.state = {
			percent: 0
		};

		this.handleScroll = this.handleScroll.bind(this);
	}
	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, false);
	}
	handleScroll() {
		const pageY = window.scrollY;
		const maxY = 700;
		let percent = 0;

		if (pageY < maxY) {
			percent = pageY / maxY;
		} else {
			percent = 1;
		}

		this.setState({ percent });
	}
	render() {
		const { percent } = this.state;

		return (
			<div className='mast'>
				<div className='overlay' style={{'background': `url(${mast})`, 'WebkitFilter': `blur(${10 * percent}px)`}} />

				<img src={symbol} width='100' height='100' alt='symbol' style={{'WebkitFilter': `blur(${10 * percent}px)`}} />

				<Container>
					<Row>
						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 12, offset: 0 }} style={{'WebkitTransform': `translateY(${-100 * percent}px)`}}>
							<h1>Advanced &nbsp;Manufactured &nbsp;Power &nbsp;Solutions</h1>

							<p>We provide high quality engineering, development, and production services to meet all customers’ power source needs supporting national and global interests via rapid product realization to achieve accelerated cycles of learning to provide the customer with increased value including quality management.</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}