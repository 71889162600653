import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './index.scss';

export default class Footer extends Component {
	render() {
		return (
			<div className='mainSection footer'>
				<Container>
					<Row>
						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 0 }} md={{ size: 3, offset: 0 }}>
							<p><span>AMPS</span>
							<br />
							521 Rankin Rd NE
							<br />
							Albuquerque, NM 87107</p>
						</Col>

						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 6, offset: 0 }} md={{ size: 3, offset: 0 }}>
							<p><span>Contact</span>
							<br />
							Phone: (505) 508-0613
							<br />
							Email: <a href='mailto:info@ampsbattery.com'>info@ampsbattery.com</a></p>
						</Col>

						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 12, offset: 0 }} md={{ size: 6, offset: 0 }}>
							<p><span>Legal</span>
							<br />
							AMPS is registered with the U.S Department of State Directorate of Defense Trade Controls for the manufacture of ITAR regulated defense articles.</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}