import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './index.scss';

import portrait from './portrait.jpg';

export default class About extends Component {
	render() {
		return (
			<div className='mainSection about'>
				<Container>
					<Row>
						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 0 }} lg={{ size: 4, offset: 0 }}>
							<img src={portrait} width='100%' height='auto' alt='portrait' />
						</Col>

						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 12, offset: 0 }} md={{ size: 12, offset: 0 }} lg={{ size: 7, offset: 1 }}>
							<h2>About AMPS</h2>

							<p>AMPS is led by Joseph Beck and Eric Branson.  Combined, they have 10+ years experience working in the Power Sources Technology Group at Sandia National Laboratories where they lead multi-disciplinary teams responsible for designing, developing, and producing hardware for Department of Energy, Department of Defense, and other customers.  AMPS combines Eric’s background in power sources design and development with Joseph’s in production and quality to deliver high quality, high reliability power sources to the customer.
							<br /><br />
							Our target market is “high value” applications. Understanding that quality, reliability, and traceability are all critical to mission success, AMPS’ employs a rigorous quality management system throughout the development and production process.  Our products need to function as designed, every time; we deliver a rigorous documentation package with the hardware to demonstrate compliance to all requirements.
							<br /><br />
							We deliver higher value by teaming with the customer to provide unique power sources solutions.  Close partnering increases quality while shortening development and production cycles.  We seek demanding applications requiring power; high consequences (high reliability, long shelf life, etc), performance in extreme environments (mechanical, temperature, etc), and demanding power needs (high energy density, high power density, etc).</p>

							<Row>
								<Col xs={12} md={6}>
									<h3>Joseph Beck <span>(Left)</span></h3>
									<p className='caption'>President / CEO & Director of Quality<br /><a href='mailto:jbeck@ampsbattery.com'>jbeck@ampsbattery.com</a></p>
								</Col>

								<Col xs={12} md={6}>
									<h3>Eric Branson <span>(Right)</span></h3>
									<p className='caption'>Vice President / CTO & Director of Engineering<br /><a href='mailto:ebranson@ampsbattery.com'>ebranson@ampsbattery.com</a></p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}