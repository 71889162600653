import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './index.scss';

export default class QualityPolicy extends Component {
	render() {
		return (
			<div className='mainSection qualityPolicy'>
				<Container>
					<Row>
						<Col xs={{ size: 10, offset: 1 }} sm={{ size: 12, offset: 0 }}>
							<h2>Quality Policy</h2>

							<p>Advanced Manufactured Power Solutions (AMPS) will provide high quality production and testing services to meet customers’ high reliability power sources, cables, and other components needs.   Accelerated cycles of learning, achieved via rapid product realization supported by a high functioning quality management system, will provide the customer with increased value.  We will foster teamwork and engagement with our workforce and provide/offer continued training to strengthen their skills and competencies.  We will strive for zero quality defects by focusing on process execution to meet or exceed customer expectations. AMPS is committed to meet all deliverables on time by teaming with the customer, managing the supply chain, and providing the highest quality product by focusing on continuous improvement throughout the value chain.</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}