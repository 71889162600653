import React, { Component } from 'react';
// import { connect } from 'react-redux';

import Nav from 'components/Nav';
import Mast from 'components/Mast';
import About from 'components/About';
import ProductsAndServices from 'components/ProductsAndServices';
import QualityPolicy from 'components/QualityPolicy';
import Footer from 'components/Footer';

// import { updateCount } from 'actions';

// import { track } from 'adt-utils/adlp';

import 'App.scss';

export default class App extends Component {
	// constructor(props) {
	// 	super(props);

	// 	this.clickHandler = this.clickHandler.bind(this);
	// }
	// clickHandler() {
	// 	const { dispatch, count } = this.props;

	// 	dispatch( updateCount(count + 1) );
	// }
  render() {
		// const { count } = this.props;

    return (
			<div className='app'>
				<Nav />

				<Mast />

				<ProductsAndServices />
			
				<QualityPolicy />

				<About />

				<Footer />
			</div>
    );
  }
}

// const mapStateToProps = state => ({
//   count: state.count
// });

// export default connect(mapStateToProps)(App);