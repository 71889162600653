import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';

import './index.scss';

// import weld from './weld.jpg';
// import temp from './temp.png';
import batteryYellow from './battery-yellow.svg';
import welderYellow from './welder-yellow.svg';
import gearYellow from './gear-yellow.svg';
// import batteryRed from './battery-red.svg';
// import welderRed from './welder-red.svg';
// import gearRed from './gear-red.svg';

export default class ProductsAndServices extends Component {
	// constructor(props) {
	// 	super(props);

	// 	this.state = {
	// 		colorPref: 'yellow'
	// 	};
	// }
	// componentDidMount() {
	// 	const colorPref = window.location.href.split('colorPref=')[1];

	// 	this.setState({ colorPref });
	// }
	render() {
		// const { colorPref } = this.state;
		// const batteryImg = colorPref === 'yellow' ? batteryYellow : batteryRed;
		// const gearImg = colorPref === 'yellow' ? gearYellow : gearRed;
		// const welderImg = colorPref === 'yellow' ? welderYellow : welderRed;
		const batteryImg = batteryYellow;
		const gearImg = gearYellow;
		const welderImg = welderYellow;

		return (
			<div className='mainSection productsAndServices'>
				<Container>
					<Row>
						<Col xs={12}>
							<h2>Products and Services</h2>
						</Col>
							
						<Col xs={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 0 }}>
							<img src={batteryImg} width='150' height='150' alt='battery' />

							<h3>Product Realization</h3>

							<p>Rapid product realization, accelerated cycles of learning, and design for manufacturing feedback of primary (non-rechargeable) power assemblies, secondary (rechargeable) power assemblies, hybrid power assemblies, cables and other components.</p>
						</Col>

						<Col xs={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 0 }}>
							<img src={gearImg} width='150' height='150' alt='gear' />
							
							<h3>Production</h3>

							<p>Build-to-print to customer product definition, custom designed and developed to customer requirements, and complete quality documentation of high quality, high reliability power sources, cables and other components.</p>
						</Col>

						<Col xs={{ size: 10, offset: 1 }} lg={{ size: 4, offset: 0 }}>
							<img src={welderImg} width='150' height='150' alt='welder' />
							
							<h3>Engineering Services</h3>

							<p>Testing of primary and secondary power sources, thermal batteries, and capacitors, along with electrochemisty consulting.</p>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}