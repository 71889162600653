import React, { Component } from 'react';

import './index.scss';

import logo from './logo.svg';

export default class Nav extends Component {
	render() {
		return (
			<div className='nav'>
				<img src={logo} width='auto' height='40' alt='logo' />
			</div>
		);
	}
}